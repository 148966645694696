import {
    Badge,
    Box,
    Button,
    ButtonGroup, Checkbox,
    Container, FormControl, FormLabel,
    HStack,
    Icon, IconButton,
    Input,
    InputGroup,
    InputLeftElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner,
    Stack, Table, Tbody, Td,
    Text, Textarea, Th, Thead, Tr,
    useBreakpointValue,
    useColorModeValue, useDisclosure,
} from '@chakra-ui/react'
import * as React from 'react'
import axios from "axios";
import {useEffect, useState} from "react";
import {useMutation, UseMutationResult} from 'react-query';
import {API_ADDRESS} from "../../config";
import {axiosConfig} from "../../localStore";


interface ModalProps {
    item: any;
    onClose: () => void;
}

const saveEditedItem = async (item: any) => {
    let url = `${API_ADDRESS}/manager/v1/customers`
    if (item.id) {
        url = `${API_ADDRESS}/manager/v1/customers/${item.id}`
    }
    const {data} = await axios.post(url, item, axiosConfig());
    return data;
};

export const CustomerEdit: React.FC<ModalProps> = ({item, onClose}) => {
    const {isOpen, onOpen} = useDisclosure()
    const [editedTitle, setEditedTitle] = useState(item.title);
    const [editedDescription, setEditedDescription] = useState(item.description);
    const [editedLicenseCount, setEditedLicenseCount] = useState(item.license_count);


    // const queryClient = new QueryClient()

    useEffect(() => {
        setEditedTitle(item.title);
    }, [item]);
    useEffect(() => {
        setEditedLicenseCount(item.license_count);
    }, [item]);
    useEffect(() => {
        setEditedDescription(item.description);
    }, [item]);

    const mutation: UseMutationResult<any, Error, any> = useMutation(saveEditedItem, {
        onSuccess: () => {
            // queryClient.invalidateQueries('tableData');
            onClose();
        },
        onError: (error) => {
            console.error("Error saving item:", error);
            // Handle the error, maybe show a notification or some feedback
        }
    });

    const handleSave = () => {
        mutation.mutate({...item, title: editedTitle, description: editedDescription, license_count: Number(editedLicenseCount)});
        // console.log(`Saving changes for item ${item.id} with new name ${editedTitle}`);
        // onClose();
    }

    if (!item) return null;

    return (
        <Modal
            isOpen={true}
            onClose={onClose}
        >
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader>{!item.id ? "Создание" : "Редактирование"} заказчика</ModalHeader>
                <ModalCloseButton/>
                <ModalBody pb={6}>
                    <FormControl>
                        <FormLabel>Название</FormLabel>
                        <Input
                            value={editedTitle}
                            onChange={(e) => setEditedTitle(e.target.value)}
                            placeholder='Название'/>
                    </FormControl>

                    <FormControl mt={4}>
                        <FormLabel>Описание</FormLabel>
                        <Textarea
                            value={editedDescription}
                            onChange={(e) => setEditedDescription(e.target.value)}
                            placeholder='Описание'/>
                    </FormControl>

                    <FormControl mt={4}>
                        <FormLabel>Количество лицензий</FormLabel>
                        <Input
                            value={editedLicenseCount}
                            onChange={(e) => setEditedLicenseCount(e.target.value)}
                            placeholder='1000'
                            type="tel"
                        />
                    </FormControl>
                </ModalBody>

                <ModalFooter>
                    {mutation.isLoading ?
                        <Button
                            colorScheme='blue'
                            mr={3}
                            disabled={true}
                            rightIcon={<Spinner/>}
                        >
                            Сохранение...
                        </Button>
                        :
                        <Button
                            onClick={handleSave}
                            colorScheme='blue'
                            mr={3}
                        >
                            Сохранить
                        </Button>
                    }
                    <Button onClick={onClose}>Отменить</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
