import React, {useState} from 'react';
import {
    Box, Button, Checkbox,
    Container, Divider, FormControl, FormLabel,
    Heading, HStack, Input, Spacer,
    Stack,
    Text, useToast, VStack,
} from '@chakra-ui/react';
import {Link, useLocation} from "wouter";
import {PasswordField} from "../components/PasswordField";
import {ColorModeSwitcher} from "../components/ColorModeSwitcher";
import axios from "axios";
import {useMutation} from "react-query";
import {API_ADDRESS} from "../config";

function chooseRandomOption() {
    const now = new Date();
    const seconds = now.getSeconds();
    return seconds % 2 === 0 ? 1 : 2;
    // return Math.random() < 0.5 ? 1 : 2;
}

const loginMutation = async (userData: any) => {
    const response = await axios.post(`${API_ADDRESS}/manager/v1/auth/token`, userData);
    if (response.status !== 200) {
        throw new Error(response.data.message || "Failed to login");
    }
    return response.data;
};

export const Login = (props: { onLogin: any }) => {
    const [editedUsername, setEditedUsername] = useState("");
    const [editedPassword, setEditedPassword] = useState("");

    const [randomOption] = useState(chooseRandomOption());


    const handleSubmit = (e: any) => {
        e.preventDefault();
        mutation.mutate({"username": editedUsername, "password": editedPassword});
        // onLogin({editedUsername, editedPassword});
    };

    const toast = useToast();
    const mutation = useMutation(loginMutation, {
        onSuccess: (data: any) => {
            localStorage.setItem("token", data.token);
            localStorage.setItem("username", data.username);
            localStorage.setItem("customer_id", data.customer_id);
            localStorage.setItem("is_staff", data.is_staff);
            toast({
                      title: "Авторизация успешна",
                      description: `Добро пожаловать, ${data.title}!`,
                      status: "success",
                      duration: 3000,
                      isClosable: true,
                  });
            props.onLogin(data.token);
        },
        onError: (error: any) => {
            toast({
                      title: "Ошибка авторизации",
                      description: error.message,
                      status: "error",
                      duration: 3000,
                      isClosable: true,
                  });
        },
    });

    return (
        <Container maxW="lg" py={{base: '12', md: '24'}} px={{base: '0', sm: '8'}}>
            <Stack spacing="8">
                <Stack spacing="6"
                    // mb={10}
                >
                    <Stack spacing={{base: '2', md: '3'}}>
                        {randomOption === 1 ? (
                            <HStack>
                                <Spacer/>
                                <Heading fontSize={60}>🏃‍💨</Heading>
                                <Box pl={5}>
                                    <Heading
                                        fontSize={28}
                                        backgroundImage={"-webkit-linear-gradient(135deg,#0081c7,#39b382)"}
                                        backgroundClip={"text"}
                                    >Личный кабинет<br/>Go.Lar.tech</Heading>
                                </Box>
                                <Spacer/>
                            </HStack>
                        ) : (
                            <HStack>
                                <Spacer/>
                                <Box pr={5}>
                                    <Heading
                                        fontSize={28}
                                        backgroundImage={"-webkit-linear-gradient(135deg,#0081c7,#39b382)"}
                                        backgroundClip={"text"}
                                        textAlign={"right"}
                                    >Личный кабинет<br/>Go.Lar.tech</Heading>
                                </Box>

                                <Heading fontSize={60} transform={"scale(-1, 1)"}>🏃‍💨</Heading>
                                <Spacer/>
                            </HStack>
                        )}
                    </Stack>
                </Stack>
                <Box
                    py={{base: '0', sm: '8'}}
                    px={{base: '4', sm: '10'}}
                    bg={{base: 'transparent', sm: 'bg.surface'}}
                    boxShadow={{base: 'xl', sm: 'base'}}
                    borderRadius={{base: 'none', sm: 'xl'}}
                >
                    <form onSubmit={handleSubmit}>
                        <Stack spacing="6">
                            <Stack spacing="5">
                                <FormControl>
                                    <FormLabel htmlFor="username">Имя пользователя</FormLabel>
                                    <Input
                                        value={editedUsername}
                                        onChange={(e) => setEditedUsername(e.target.value)}
                                        borderColor="#39b382"
                                        _focus={{borderColor: "#39b382"}}
                                        id="username"
                                        type="text"/>
                                </FormControl>
                                {/*
                            <PasswordField/>
                            */}
                                <FormControl>
                                    <FormLabel htmlFor="username">Пароль</FormLabel>
                                    <Input
                                        value={editedPassword}
                                        onChange={(e) => setEditedPassword(e.target.value)}
                                        type={"password"}
                                        borderColor="#39b382"
                                        _focus={{borderColor: "#39b382"}}
                                        id="password"/>
                                </FormControl>
                            </Stack>
                            <HStack justify="space-between">
                                <Checkbox
                                    defaultChecked
                                >Запомнить меня</Checkbox>
                                {/*
            <Button variant="text" size="sm">
              Forgot password?
            </Button>
            */}
                            </HStack>
                            <Stack spacing="6">
                                <Button type={"submit"}>Войти</Button>
                                <HStack>
                                    <Divider/>
                                </HStack>
                                <Stack spacing={{base: '2', md: '3'}} textAlign="center">
                                    <Text color="fg.muted">
                                        Нет доступа к аккаунту?<br/><Link href="#">Обратитесь в поддержку</Link>
                                    </Text>
                                </Stack>
                            </Stack>
                        </Stack>
                    </form>
                </Box>
                <ColorModeSwitcher/>
            </Stack>
        </Container>
    );
};
