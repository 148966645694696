export const getToken = () => {
    return localStorage.getItem("token")
}

export const isStaff = () => {
    return localStorage.getItem("is_staff") === "true"
}

export const axiosConfig = () => {
    return {
        headers: {"X-API-Key": getToken()}
    }
}
