import React, {useCallback, useState} from 'react';
import {YMaps, Map, ZoomControl, FullscreenControl, GeolocationControl, SearchControl, TrafficControl} from 'react-yandex-maps';
import {Helmet} from "react-helmet";
import {array} from "yup";


export const HeatMap = (props: { latitude?: number, longitude?: number, coordinates?: any }) => {
    const [mapInstance, setMapInstance] = useState<any | null>(null);
    const [mapLibLoaded, setMapLibLoaded] = useState(0);

    const handleMapRef = useCallback((instance: any) => {
        if (instance) setMapInstance(instance);
    }, []);

    const onYmapsLoad = (ymaps: any) => {
        window.ymaps = ymaps;
        setMapLibLoaded(1);
    };

    const onHeatmapLibraryLoad = (addedTags: any) => {
        if (addedTags && addedTags.scriptTags) {
            addedTags.scriptTags[0].onload = onHeatmapLibraryLoad2
        }
    }

    const onHeatmapLibraryLoad2 = () => {
        window.ymaps.modules.require(['Heatmap'], function (Heatmap: any) {
            let gradients = [{
                    0.1: 'rgba(128, 255, 0, 0.7)',
                    0.2: 'rgba(255, 255, 0, 0.8)',
                    0.7: 'rgba(234, 72, 58, 0.9)',
                    1.0: 'rgba(162, 36, 25, 1)'
                }, {
                    0.1: 'rgba(162, 36, 25, 0.7)',
                    0.2: 'rgba(234, 72, 58, 0.8)',
                    0.7: 'rgba(255, 255, 0, 0.9)',
                    1.0: 'rgba(128, 255, 0, 1)'
                }],
                radiuses = [5, 10, 20, 30],
                opacities = [0.4, 0.6, 0.8, 1];


            let data = []
            if (props.latitude) {
                data.push([props.latitude, props.longitude])
            }
            if (props.coordinates && props.coordinates.length > 0) {
                data = props.coordinates
            }
            let heatmap = new Heatmap(
                data,
                {
                    gradient: gradients[0],
                    radius: radiuses[3],
                    opacity: opacities[2]
                });

            heatmap.setMap(mapInstance);
        });
    }

    let spb = [59.9343, 30.3351]
    let center = spb
    if (props.latitude && props.longitude) {
        center = [props.latitude, props.longitude]
    }
    if (props.coordinates && props.coordinates.length > 0) {
        center = props.coordinates[0]
    }

    return (
        <YMaps>
            <Helmet onChangeClientState={(newState, addedTags) => onHeatmapLibraryLoad(addedTags)}>
                {mapLibLoaded === 1 &&
                  <script src="https://1831b628-1ee7-4286-bfe8-ac42dbd720c8.selstorage.ru/Heatmap.min.js"
                          type="text/javascript"></script>
                }
            </Helmet>
            <Map
                width="100%"
                height="100%"
                defaultState={{
                    center: center,
                    zoom: center == spb ? 12 : 15,
                }}
                instanceRef={handleMapRef}
                onLoad={onYmapsLoad}>
                <ZoomControl/>
                <FullscreenControl/>
                <GeolocationControl/>
                <SearchControl/>
                <TrafficControl/>
            </Map>
        </YMaps>
    );
}

