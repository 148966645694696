import {
    AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay,
    Badge,
    Box,
    Button,
    ButtonGroup, Checkbox,
    Container, FormControl, FormLabel,
    HStack,
    Icon, IconButton,
    Input,
    InputGroup,
    InputLeftElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay,
    Stack, Table, Tbody, Td,
    Text, Th, Thead, Tr,
    useBreakpointValue,
    useColorModeValue, useDisclosure,
} from '@chakra-ui/react'
import moment from "moment";
import * as React from 'react'
import {FiEdit2, FiSearch, FiTrash2} from 'react-icons/fi'
import {IoArrowDown} from "react-icons/io5";
import axios from "axios";
import {useEffect, useRef, useState} from "react";
import {useQuery} from "react-query";
import debounce from 'lodash/debounce';
import {ProjectEdit} from "../ProjectEdit/ProjectEdit";
import {Link, useLocation, useParams} from "wouter";
import {Link as ChakraLink, LinkProps} from '@chakra-ui/react'
import {axiosConfig} from "../../localStore";
import {API_ADDRESS} from "../../config";
import {Loading} from "../../pages/Loading";
import {UserEdit} from "../UserEdit/UserEdit";
import {Title} from "../Title";

const fetchTableData = async (customerId: string, page: number, searchTerm: string) => {
    const {data} = await axios.get(`${API_ADDRESS}/manager/v1/customers/${customerId}/users?page=${page}&search=${searchTerm}`, axiosConfig());
    return data;
};

export const Users = (props: { customer: any }) => {
    const params = useParams();

    const isMobile = useBreakpointValue({base: true, md: false})
    const colorMode = useColorModeValue('sm', 'sm-dark')
    const borderRadius = useBreakpointValue({base: 'none', md: 'lg'})

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState<any | null>(null);

    // retrieve
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>(searchTerm);
    useEffect(() => {
        const handleDebounce = debounce(() => setDebouncedSearchTerm(searchTerm), 500);
        handleDebounce();

        return () => {
            handleDebounce.cancel();
        };
    }, [searchTerm]);

    const {data, isError, isLoading, refetch} =
        useQuery(['tableUsersData', params.customerId, currentPage, debouncedSearchTerm], () =>
            fetchTableData(params.customerId, currentPage, debouncedSearchTerm), {
                     staleTime: 5000,
                     keepPreviousData: true // for better pagination transition
                 });

    // add-edit
    const handleEditClick = (item: any) => {
        setSelectedItem(item);
        setIsModalOpen(true);
    }
    // delete
    const [isDeleteAlertOpen, setDeleteAlertOpen] = useState(false);
    const cancelRef = useRef<HTMLButtonElement | null>(null);
    const handleDeleteClick = (item: any) => {
        setSelectedItem(item);
        setDeleteAlertOpen(true);
    }
    const deleteSelectedClick = async (item: any) => {
        await axios.delete(`${API_ADDRESS}/manager/v1/customers/${params.customerId}/users/${item.id}`, axiosConfig());
        refetch();
        setDeleteAlertOpen(false);
    }

    if (isLoading) return <Loading/>;
    if (isError || !data) return <div>Error loading data</div>;

    // console.log(data);

    return (
        <Box
            // py={{ base: '4', md: '8' }}
            // px={{ base: '0', md: 8 }}
            p="0" m={0} w="100%"
        >
            <Box
                bg="bg-surface"
                boxShadow={{base: 'none', md: colorMode}}
                borderRadius={borderRadius}
            >
                <Stack spacing="5">
                    <Box px={{base: '4', md: '6'}} pt="5">
                        <Stack
                            direction={{base: 'column', md: 'row'}}
                            justify="space-between"
                        >
                            <Title>
                                Пользователи
                            </Title>
                            <InputGroup maxW="xs">
                                <InputLeftElement pointerEvents="none">
                                    <Icon as={FiSearch} color="muted" boxSize="5"/>
                                </InputLeftElement>
                                <Input
                                    type="text"
                                    placeholder="Поиск"
                                    value={searchTerm}
                                    onChange={e => {
                                        setSearchTerm(e.target.value);
                                        setCurrentPage(1); // Reset to the first page when searching
                                    }}
                                />
                            </InputGroup>
                            <Button onClick={() => handleEditClick({})}>Создать</Button>
                        </Stack>
                    </Box>
                    <Box
                        // overflowX="auto"
                        w="full"
                    >
                        <Table>
                            <Thead>
                                <Tr>
                                    <Th>
                                        <HStack spacing="3">
                                            {/*<Checkbox/>*/}
                                            <HStack spacing="1">
                                                <Text>Создан</Text>
                                                <Icon as={IoArrowDown} color="muted" boxSize="4"/>
                                            </HStack>
                                        </HStack>
                                    </Th>
                                    <Th>Имя пользователя</Th>
                                    <Th>Статус</Th>
                                    <Th>Имя</Th>
                                    <Th>Фамилия</Th>
                                    <Th>Телефон</Th>
                                    <Th>Администратор</Th>
                                    {/*<Th>Role</Th>*/}
                                    {/*<Th>Rating</Th>*/}
                                    <Th></Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {data.items.map((item: any) => (
                                    <Tr key={item.id}>
                                        <Td>
                                            <HStack spacing="3">
                                                {/*<Checkbox/>*/}
                                                <Text color="muted">{moment(item.created).format('DD.MM.YYYY, HH:mm')}</Text>
                                                {/*<Avatar name={member.name} src={member.avatarUrl} boxSize="10" />*/}
                                            </HStack>
                                        </Td>
                                        <Td>
                                            <Box>
                                                <Text>
                                                    {item.username}
                                                </Text>
                                            </Box>
                                        </Td>
                                        <Td>
                                            <Badge size="sm" colorScheme={'green'}>
                                                {item.is_active ? 'Активен' : 'Не активен'}
                                            </Badge>
                                            {/*
                                            <Badge size="sm" colorScheme={member.status === 'active' ? 'green' : 'red'}>
                                                {member.status}
                                            </Badge>
                                            */}
                                        </Td>
                                        <Td>
                                            <Text color="muted">{item.first_name}</Text>
                                        </Td>
                                        <Td>
                                            <Text color="muted">{item.last_name}</Text>
                                        </Td>
                                        <Td>
                                            <Text color="muted">{item.phone}</Text>
                                        </Td>
                                        <Td>
                                            <Text color="muted">{item.is_admin ? 'Да' : 'Нет'}</Text>
                                        </Td>
                                        {/*<Td>*/}
                                        {/*  <Text color="muted">{member.role}</Text>*/}
                                        {/*</Td>*/}
                                        {/*<Td>*/}
                                        {/*  <Text color="muted">*/}
                                        {/*    <Rating defaultValue={member.rating} size="xl" />*/}
                                        {/*  </Text>*/}
                                        {/*</Td>*/}
                                        <Td>
                                            <HStack spacing="1">
                                                <IconButton
                                                    icon={<FiEdit2 fontSize="1.25rem"/>}
                                                    variant="ghost"
                                                    aria-label="Редактировать"
                                                    onClick={() => handleEditClick(item)}
                                                />
                                                <IconButton
                                                    icon={<FiTrash2 fontSize="1.25rem"/>}
                                                    variant="ghost"
                                                    aria-label="Удалить"
                                                    onClick={() => handleDeleteClick(item)}
                                                />
                                            </HStack>
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </Box>
                    <Box px={{base: '4', md: '6'}} pb="5">
                        <HStack spacing="3" justify="space-between">
                            {!isMobile && (
                                <Text color="muted" fontSize="sm">
                                    Страница {data.paginator.page} из {data.paginator.num_pages} ({data.paginator.count} записей)
                                </Text>
                            )}
                            <ButtonGroup
                                spacing="3"
                                justifyContent="space-between"
                                width={{base: 'full', md: 'auto'}}
                                variant="secondary"
                            >
                                <Button
                                    onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                                    disabled={data.paginator.page === 1}
                                >Предыдущая</Button>
                                <Button
                                    onClick={() => setCurrentPage(prev => prev + 1)}
                                    disabled={data.paginator.page === data.paginator.num_pages}
                                >Следующая</Button>
                            </ButtonGroup>
                        </HStack>
                    </Box>
                </Stack>
            </Box>
            {isModalOpen && <UserEdit customer={props.customer} customerId={params.customerId} item={selectedItem} onClose={() => {
                setIsModalOpen(false);
                refetch()
            }}/>}
            <AlertDialog
                isOpen={isDeleteAlertOpen}
                leastDestructiveRef={cancelRef}
                onClose={() => setDeleteAlertOpen(false)}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Удаление пользователя
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Вы точно хотите удалить пользователя {selectedItem && selectedItem.username}?
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={() => setDeleteAlertOpen(false)}>
                                Отменить
                            </Button>
                            <Button colorScheme='red' onClick={() => deleteSelectedClick(selectedItem)} ml={3}>
                                Удалить
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </Box>
    )
}
