import React, {ReactNode, useState} from 'react';
import {
    useMediaQuery,
} from '@chakra-ui/react';
import {Route, Router, useLocation, useParams} from 'wouter';
import './App.css';
import {Login} from "./pages/Login";
import {MainApp} from "./components/App/App";
import {getToken} from "./localStore";


function App() {
    const [location, setLocation] = useLocation();

    const [token, setToken] = useState(getToken());

    if (!token || token == "") {
        return <Login onLogin={(token: string) => {
            setToken(token)
            setLocation("/")
        }}/>
    }

    return <MainApp
        // task={taskData} customer={customerData} project={projectData}
    />
}


export default App;

