import {
    Box, Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Circle,
    Divider,
    Flex,
    Heading,
    HStack,
    Spacer,
    Stack,
    Text,
    useColorModeValue as mode
} from '@chakra-ui/react'
import * as React from 'react'
import {
    BiBuoy,
    BiCog, BiDevices,
} from 'react-icons/bi'
import {AccountSwitcher} from './AccountSwitcher'
import {NavGroup} from './NavGroup'
import {NavItem} from './NavItem'
import {ProjectsTableWithSearch} from "../Projects/Projects";
import {FiBarChart2, FiBookmark, FiCheckSquare, FiMap, FiMenu, FiUsers, FiLogOut, FiHome} from "react-icons/fi";
import {Link, Route, Router, useLocation, useParams} from "wouter";
import {Soon} from "../../pages/Soon";
import {BsJournalText, BsFiletypeDoc, BsFiletypeXlsx} from "react-icons/bs";
import {ColorModeSwitcher} from "../ColorModeSwitcher";
import {Logout} from "../../pages/Logout";
import {Users} from "../Users/Users";
import {Tasks} from "../Tasks/Tasks";
import {ProjectDashboard} from "../ProjectDashboard";
import axios from "axios";
import {API_ADDRESS} from "../../config";
import {axiosConfig, isStaff} from "../../localStore";
import {useQuery} from "react-query";
import {CustomersTableWithSearch} from "../Customers/Customers";
import {HeatMap} from "../Map/Map";
import {Redirect} from "../../pages/Redirect";
import {Task} from "../Task/Task";
import {ChevronRightIcon} from "@chakra-ui/icons";
import {Device} from "../Device/Device";
import {Devices} from "../Devices/Devices";
import {Reports} from "../Reports/Reports";
import {WaitData} from "../../pages/WaitData";
import moment from "moment/moment";
import {CustomerDashboard} from "../CustomerDashboard";
import {Loading} from "../../pages/Loading";


function MyBreadcrumbItem(props: { subtle?: boolean, label: string, to: string }) {
    const [location, setLocation] = useLocation();
    return (

        <Box onClick={(e) => {
            e.preventDefault();
            setLocation(props.to);
        }}>
            <BreadcrumbLink href={props.to}>{props.label}</BreadcrumbLink>
        </Box>

    );
}

function MyMenuItem(props: { subtle?: boolean, include?: boolean, icon: JSX.Element, label: string, to: string }) {
    const [location, setLocation] = useLocation();
    return (
        <Box onClick={(e) => {
            setLocation(props.to);
        }}>
            <NavItem
                subtle={props.subtle}
                active={!props.include ? location === props.to : location.startsWith(props.to)}
                icon={props.icon}
                label={props.label}
            />
        </Box>
    );
}

const fetchTask = async (customerId: string, projectId: string, taskId: string) => {
    if (customerId === "" || projectId === "" || taskId === "") {
        return {}
    }
    const {data} = await axios.get(`${API_ADDRESS}/manager/v1/customers/${customerId}/projects/${projectId}/tasks/${taskId}`, axiosConfig());
    return data;
};

const fetchDevice = async (customerId: string, projectId: string, deviceId: string) => {
    if (customerId === "" || projectId === "" || deviceId === "") {
        return {}
    }
    const {data} = await axios.get(`${API_ADDRESS}/manager/v1/customers/${customerId}/projects/${projectId}/devices/${deviceId}`, axiosConfig());
    return data;
};

const fetchProject = async (customerId: string, projectId: string) => {
    if (customerId === "" || projectId === "") {
        return {}
    }
    const {data} = await axios.get(`${API_ADDRESS}/manager/v1/customers/${customerId}/projects/${projectId}`, axiosConfig());
    return data;
};

const fetchCustomer = async (customerId: string) => {
    if (customerId === "") {
        return {}
    }
    const {data} = await axios.get(`${API_ADDRESS}/manager/v1/customers/${customerId}`, axiosConfig());
    return data;
};

export const MainApp = () => {
    const [location, setLocation] = useLocation();

    let customerId = "";
    let projectId = "";
    let taskId = "";
    let deviceId = "";
    let parts = location.split('/').filter(part => part);
    if (parts.length > 1) {
        customerId = parts[1];
    }
    if (parts.length > 3) {
        projectId = parts[3];
    }
    if (parts.length > 5 && parts[4] === "tasks") {
        taskId = parts[5];
    }
    if (parts.length > 5 && parts[4] === "devices") {
        deviceId = parts[5];
    }

    const queryOptions = {
        staleTime: 60 * 1000 * 60,
        cacheTime: 60 * 1000 * 60,
    }

    const {data: customer, isError, isLoading, refetch} =
        useQuery(['customer', customerId], () => fetchCustomer(customerId), queryOptions);

    const {data: project} =
        useQuery(['project', customerId, projectId], () => fetchProject(customerId, projectId), queryOptions);

    const {data: device} =
        useQuery(['device', customerId, projectId, deviceId], () => fetchDevice(customerId, projectId, deviceId), queryOptions);

    const {data: task} =
        useQuery(['task', customerId, projectId, taskId], () => fetchTask(customerId, projectId, taskId), queryOptions);

    if (isLoading || (customerId && !customer)) return <Loading/>;
    if (isLoading || (projectId && !project)) return <Loading/>;
    if (isLoading || (deviceId && !device)) return <Loading/>;
    if (isLoading || (taskId && !task)) return <Loading/>;

    return (
        <Box height="100vh"
            // overflow="hidden"
             position="relative">
            <Flex
                h="full"
                id="app-container">
                <Box w="64"
                     style={{"backgroundColor": "#052d3b"}}
                    // backgroundImage={"-webkit-linear-gradient(135deg,#0a546e,#052d3b)"}
                     color="white" fontSize="sm">
                    <Flex
                        // position="sticky"
                        h="full"
                        // h="fit-content"
                        direction="column"
                        px="4" py="4"
                    >
                        {/*<AccountSwitcher/>*/}
                        <HStack
                            pb={3} pl={2}
                        >
                            <Box pr={3}>
                                <Heading
                                    fontSize={25}
                                    backgroundImage={"-webkit-linear-gradient(135deg,#0081c7,#39b382)"}
                                    backgroundClip={"text"}
                                    // textAlign={"right"}
                                    cursor={"pointer"}
                                    onClick={() => {
                                        setLocation("/");
                                    }}
                                >Go.Lar.tech</Heading>
                            </Box>
                            <Heading fontSize={23} transform={"scale(-1, 1)"}>🏃‍💨</Heading>

                            {/*<Spacer/>*/}
                        </HStack>
                        {/*<Divider/>*/}
                        <Stack spacing="2" flex="1" overflow="auto"
                               pt={((!customer || !customer.id) && (!project || !project.id)) ? 4 : 0}>
                            {/*<Stack spacing="1">*/}
                            {/*    <NavItem active icon={<BiHome/>} label="Get Started"/>*/}
                            {/*    <NavItem icon={<BiCommentAdd/>} label="Inbox"/>*/}
                            {/*</Stack>*/}
                            {task && task.id && task.id === "111" &&
                              <NavGroup
                                label=""
                              >
                                <Box
                                  px={4}
                                  py={2}
                                  mb={3}
                                  borderRadius="lg"
                                    // boxShadow="md"
                                    // bg="rgba(0, 129, 199, 0.05)"
                                  backgroundImage={"-webkit-linear-gradient(135deg,rgba(0, 129, 199, 0.2),rgba(57, 179, 130, 0.2))"}
                                >
                                  <Stack>
                                    <Text
                                      textStyle="sm"
                                      fontSize={"13px"}
                                      color="rgba(255, 255, 255, 0.9)"
                                    >
                                      Задача {task.eui}
                                      <Box
                                        color="rgba(255, 255, 255, 0.5)"
                                      >ID {task.id}</Box>

                                        {/*
                                        {project.description !== "" &&
                                          <Box
                                            color="rgba(255, 255, 255, 0.5)"
                                          >{project.description}</Box>}
                                        */}
                                    </Text>
                                  </Stack>
                                </Box>

                                  {/*<MyMenuItem label={"Отчет"} to={`/customers/${parts[1]}/projects/${parts[3]}/tasks/${parts[5]}`}*/}
                                  {/*            icon={<FiBarChart2/>}></MyMenuItem>*/}

                                  {/*<NavItem icon={<BiRedo/>} label="Transfer"/>*/}
                                <Divider pt={1}/>
                              </NavGroup>
                            }
                            {project && project.id &&
                              <NavGroup
                                label=""
                              >
                                <Box
                                  px={4}
                                  py={2}
                                  mb={3}
                                  borderRadius="lg"
                                    // boxShadow="md"
                                    // bg="rgba(0, 129, 199, 0.05)"
                                  backgroundImage={
                                      task && task.id && task.id === "111" ?
                                          "-webkit-linear-gradient(135deg,rgba(0, 129, 199, 0.09),rgba(57, 179, 130, 0.05))"
                                          :
                                          "-webkit-linear-gradient(135deg,rgba(0, 129, 199, 0.2),rgba(57, 179, 130, 0.2))"
                                  }
                                >
                                  <Stack>
                                    <Text
                                      textStyle="sm"
                                      fontSize={"13px"}
                                      color="rgba(255, 255, 255, 0.9)"
                                    >
                                        Проект {project.title}
                                      <Box
                                        color="rgba(255, 255, 255, 0.5)"
                                      >ID {project.id}</Box>

                                        {/*
                                        {project.description !== "" &&
                                          <Box
                                            color="rgba(255, 255, 255, 0.5)"
                                          >{project.description}</Box>}
                                        */}
                                    </Text>
                                  </Stack>
                                </Box>

                                <MyMenuItem label={"Статистика"} to={`/customers/${customer.id}/projects/${project.id}`}
                                            icon={<FiBarChart2/>}></MyMenuItem>
                                <MyMenuItem label={"Устройства"} to={`/customers/${customer.id}/projects/${project.id}/devices`}
                                            include={true}
                                            icon={<BiDevices/>}></MyMenuItem>
                                <MyMenuItem label={"Задачи"} to={`/customers/${customer.id}/projects/${project.id}/tasks`} include={true}
                                            icon={<FiCheckSquare/>}></MyMenuItem>
                                  {/*
                                <MyMenuItem label={"Отчеты"} to={`/customers/${customer.id}/projects/${project.id}/reports`} include={true}
                                            icon={<BsFiletypeXlsx/>}></MyMenuItem>
                                */}

                                  {/*<NavItem icon={<BiRedo/>} label="Transfer"/>*/}
                                <Divider pt={3}/>
                              </NavGroup>
                            }

                            {customer && customer.id &&
                              <NavGroup
                                label=""
                              >
                                <Box
                                  px={4}
                                  py={2}
                                  mb={3}
                                  borderRadius="lg"
                                    // boxShadow="md"
                                    // bg="rgba(0, 129, 199, 0.05)"
                                  backgroundImage={
                                      project && project.id ?
                                          "-webkit-linear-gradient(135deg,rgba(0, 129, 199, 0.09),rgba(57, 179, 130, 0.05))"
                                          :
                                          "-webkit-linear-gradient(135deg,rgba(0, 129, 199, 0.2),rgba(57, 179, 130, 0.2))"
                                  }
                                >
                                  <Stack>
                                    <Text
                                      textStyle="sm"
                                      fontSize={"13px"}
                                      color="rgba(255, 255, 255, 0.9)"
                                    >
                                        {customer.title}
                                      <Box color="rgba(255, 255, 255, 0.5)">ID {customer.id}</Box>
                                      <Box
                                        color="rgba(255, 255, 255, 0.5)">Лицензий {customer.license_count - customer.license_used},</Box>

                                        {moment(customer.subscription_start).year() > 2000 &&
                                          <Box color="rgba(255, 255, 255, 0.5)">
                                            до {moment(customer.subscription_end).format('DD.MM.YYYY')}
                                          </Box>
                                        }

                                        {/*
                                        {project.description !== "" &&
                                          <Box
                                            color="rgba(255, 255, 255, 0.5)"
                                          >{project.description}</Box>}
                                        */}
                                    </Text>
                                  </Stack>
                                </Box>

                                <MyMenuItem label={"Статистика"} to={`/customers/${customer.id}`} icon={<FiBarChart2/>}></MyMenuItem>
                                <MyMenuItem label={"Проекты"} to={`/customers/${customer.id}/projects`} icon={<FiMenu/>}></MyMenuItem>
                                <MyMenuItem label={"Пользователи"} to={`/customers/${customer.id}/users`} icon={<FiUsers/>}></MyMenuItem>

                                  {isStaff() &&
                                    <Divider pt={3}/>
                                  }
                              </NavGroup>
                            }

                            {isStaff() &&
                              <Box pt={2}>
                                <NavGroup label="Администрирование">
                                  <MyMenuItem label={"Клиенты"} to={"/customers"} icon={<FiUsers/>}></MyMenuItem>
                                    {/*<MyMenuItem label={"Карта"} to={"/map"} icon={<FiMap/>}></MyMenuItem>*/}
                                    {/*<MyMenuItem label={"Журнал"} to={"/journal"} icon={<BsJournalText/>}></MyMenuItem>*/}
                                    {/*<NavItem icon={<BiEnvelope/>} label="Invoices"/>*/}
                                    {/*<NavItem icon={<BiPurchaseTagAlt/>} label="Plans"/>*/}
                                    {/*<NavItem icon={<BiRecycle/>} label="Subscription"/>*/}
                                </NavGroup>
                              </Box>
                            }
                        </Stack>
                        <Box>
                            <Stack spacing="1">
                                {/*<MyMenuItem subtle label={"Настройки"} to={"/settings"} icon={<BiCog/>}/>*/}
                                <MyMenuItem subtle label={"Выход"} to={"/logout"} icon={<FiLogOut/>}/>
                                <NavItem
                                    subtle
                                    href={"https://lar.tech/contact"}
                                    icon={<BiBuoy/>}
                                    label="Поддержка"
                                    // endElement={<Circle size="2" bg="blue.400"/>}
                                />
                                <Divider pt={5}/>
                                <ColorModeSwitcher/>
                            </Stack>
                        </Box>
                    </Flex>
                </Box>
                <Box
                    bg={mode('white', 'gray.800')}
                    flex="1"
                    w="full"
                    overflowY="scroll"
                >
                    <Breadcrumb px={6} py={3} spacing='8px' separator={<ChevronRightIcon color='gray.500'/>}>
                        {isStaff() && location && location.indexOf("customers") > 0 &&
                          <BreadcrumbItem>
                            <MyBreadcrumbItem to='/customers' label='Клиенты'/>
                          </BreadcrumbItem>
                        }
                        {location && location.indexOf("map") > 0 &&
                          <BreadcrumbItem>
                            <MyBreadcrumbItem to={`/map`} label={`Карта`}/>
                          </BreadcrumbItem>
                        }
                        {location && location.indexOf("journal") > 0 &&
                          <BreadcrumbItem>
                            <MyBreadcrumbItem to={`/journal`} label={`Журнал`}/>
                          </BreadcrumbItem>
                        }

                        {customer && customer.id &&
                          <BreadcrumbItem>
                            <MyBreadcrumbItem to={`/customers/${customer.id}`} label={`${customer.title}`}/>
                          </BreadcrumbItem>
                        }

                        {customer && location && location.indexOf("/projects") > 0 &&
                          <BreadcrumbItem>
                            <MyBreadcrumbItem to={`/customers/${customer.id}/projects`} label={`Проекты`}/>
                          </BreadcrumbItem>
                        }
                        {customer && location && location.indexOf("/users") > 0 &&
                          <BreadcrumbItem>
                            <MyBreadcrumbItem to={`/customers/${customer.id}/users`} label={`Пользователи`}/>
                          </BreadcrumbItem>
                        }

                        {customer && project && project.id &&
                          <BreadcrumbItem>
                            <MyBreadcrumbItem to={`/customers/${customer.id}/projects/${project.id}`} label={`${project.title}`}/>
                          </BreadcrumbItem>
                        }

                        {customer && project && location && location.endsWith(`${project.id}`) &&
                          <BreadcrumbItem>
                            <MyBreadcrumbItem to={`/customers/${customer.id}/projects/${project.id}`} label={`Статистика`}/>
                          </BreadcrumbItem>
                        }

                        {customer && project && location && location.indexOf("/tasks") > 0 &&
                          <BreadcrumbItem>
                            <MyBreadcrumbItem to={`/customers/${customer.id}/projects/${project.id}/tasks`} label={`Задачи`}/>
                          </BreadcrumbItem>
                        }
                        {customer && project && location && location.indexOf("/reports") > 0 &&
                          <BreadcrumbItem>
                            <MyBreadcrumbItem to={`/customers/${customer.id}/projects/${project.id}/reports`} label={`Отчеты`}/>
                          </BreadcrumbItem>
                        }
                        {customer && project && location && location.indexOf("/devices") > 0 &&
                          <BreadcrumbItem>
                            <MyBreadcrumbItem to={`/customers/${customer.id}/projects/${project.id}/devices`} label={`Устройства`}/>
                          </BreadcrumbItem>
                        }

                        {customer && project && task && task.id &&
                          <BreadcrumbItem>
                            <MyBreadcrumbItem to={`/customers/${customer.id}/projects/${project.id}/tasks/${task.id}`}
                                              label={`${task.eui}`}/>
                          </BreadcrumbItem>

                        }

                        {customer && project && device && device.id &&
                          <BreadcrumbItem>
                            <MyBreadcrumbItem to={`/customers/${customer.id}/projects/${project.id}/devices/${device.id}`}
                                              label={`${device.eui}`}/>
                          </BreadcrumbItem>
                        }

                    </Breadcrumb>

                    <Router>
                        {/*<Route*/}
                        {/*    path="/"*/}
                        {/*    component={() => <Goods bg={debugColor}/>}*/}
                        {/*/>*/}
                        <Route
                            path="/"
                            component={Redirect}
                        />
                        <Route
                            path="/customers"
                            component={CustomersTableWithSearch}
                        />
                        <Route path="/customers/:customerId" component={() =>
                            <CustomerDashboard customer={customer}/>
                        }
                        />
                        <Route path="/customers/:customerId/users" component={() =>
                            <Users customer={customer}/>
                        }
                        />
                        <Route
                            path="/customers/:customerId/projects"
                            component={() =>
                                <Box
                                    // bg={"blue"}
                                    w="full"
                                    // h="full"
                                    // rounded="lg"
                                    // border="3px dashed currentColor"
                                    // color={mode('gray.200', 'gray.700')}
                                    // style={{"background": "blue"}}
                                >
                                    <ProjectsTableWithSearch customer={customer}/>
                                </Box>
                            }
                        />
                        <Route path="/customers/:customerId/projects/:projectId" component={() =>
                            <ProjectDashboard project={project}/>
                        }
                        />
                        <Route path="/customers/:customerId/projects/:projectId/tasks" component={Tasks}/>
                        <Route path="/customers/:customerId/projects/:projectId/tasks/:taskId" component={() =>
                            <Task task={task}/>
                        }
                        />
                        <Route path="/customers/:customerId/projects/:projectId/devices" component={Devices}/>
                        <Route path="/customers/:customerId/projects/:projectId/devices/:deviceId" component={() =>
                            <Device device={device}/>
                        }
                        />
                        <Route path="/customers/:customerId/projects/:projectId/reports" component={Reports}/>


                        <Route path="/map"
                               component={() =>
                                   <HeatMap/>
                               }
                        />
                        <Route path="/journal" component={Soon}/>
                        <Route path="/settings" component={Soon}/>

                        <Route path="/logout" component={Logout}/>
                    </Router>


                </Box>
            </Flex>
        </Box>
    )
}
