import {
    Badge,
    Box,
    Button,
    Card,
    Container,
    Heading,
    HStack,
    Icon, IconButton,
    Image,
    SimpleGrid,
    Stack,
    Table,
    Tbody, Td,
    Text,
    Th,
    Thead,
    Tr
} from '@chakra-ui/react'
import * as React from "react";
import {Title} from "./Title";
import {API_ADDRESS} from "../config";
import {HeatMap} from "./Map/Map";
import axios from "axios";
import {axiosConfig} from "../localStore";
import {useQuery} from "react-query";
import {Loading} from "../pages/Loading";
import {useParams} from "wouter";
import {LineChart} from "./Charts/LineChart";
import moment from "moment";
import {IoArrowDown} from "react-icons/io5";
import {FiEdit2} from "react-icons/fi";

interface Props {
    label: string
    value: string
}

export const Stat = (props: Props) => {
    const {label, value, ...boxProps} = props
    return (
        <Box
            px={{base: '4', md: '6'}}
            py={{base: '5', md: '6'}}
            bg="bg.surface"
            borderRadius="lg"
            boxShadow="md"
            backgroundColor={'rgba(255, 255, 255, 0.06)'}
            {...boxProps}
        >
            <Stack>
                <Text textStyle="sm" color="fg.muted">
                    {label}
                </Text>
                <Heading size={{base: 'sm', md: 'md'}}>{value}</Heading>
            </Stack>
        </Box>
    )
}


const fetchStatsData = async (customerId: string, projectId: string) => {
    const url = `${API_ADDRESS}/manager/v1/customers/${customerId}/projects/${projectId}/stats`
    const {data} = await axios.get(url, axiosConfig());
    return data;
};

export const ProjectDashboard = (props: { project: any }) => {
    const params = useParams();

    const {data, isError, isLoading, refetch} =
        useQuery(['projectStatsData', params.customerId, params.projectId], () =>
            fetchStatsData(params.customerId, params.projectId), {
                     staleTime: 5000,
                     keepPreviousData: true // for better pagination transition
                 });

    if (isLoading || !props.project) return <Loading/>;
    if (isError || !data) return <div>Error loading data</div>;

    const stats = [
        {label: 'Всего устройств', value: data.stats.total_devices},
        {label: 'За сегодня', value: data.stats.modified_today},
        {label: 'За вчера', value: data.stats.modified_yesterday},
        {label: 'За неделю', value: data.stats.modified_week},
        {label: 'За месяц', value: data.stats.modified_month},
        {label: 'За год', value: data.stats.modified_year},
    ]

    let deviceModifiedData: any[] = []
    let deviceModifiedStats = [
        {
            "id": "deviceModifiedStats",
            // "color": "#310062",
            "data": deviceModifiedData,
        }
    ]
    data.device_modified_history.items.map((item: any) => {
        deviceModifiedStats[0].data.push(
            {
                x: moment(item.date).format('DD.MM'),
                y: item.count
            })
    })
    // console.log(deviceModifiedStats);

    return (
        <Box
            w={"100%"}
            as="section"
            py={{base: '4', md: '8'}}
            px={10}
            // bg={"red"}
        >
            <SimpleGrid
                columns={{base: 2, sm: 2, md: 3, xl: 6}}
                gap={{base: '5', md: '6'}}
            >
                {stats.map(({label, value}) => (
                    <Stat key={label} label={label} value={value}/>
                ))}
            </SimpleGrid>

            <Box
                // overflowX="auto"
                w="full"
            >
                <SimpleGrid columns={2} gap="6">
                    <Box
                        // overflowX="auto"
                        w="full"
                    >
                        <Box py={5}><Title>График</Title></Box>

                        <SimpleGrid columns={{base: 1, md: 1}} gap="6" height={300}>
                            <Card p={1}>
                                <LineChart data={deviceModifiedStats}/>
                            </Card>
                        </SimpleGrid>

                    </Box>
                    <Box
                        // overflowX="auto"
                        w="full"
                        // minH={"250px"}
                        height={300}
                    >
                        <Box px={0} py={5}>
                            <Title>Карта</Title>
                        </Box>
                        <HeatMap coordinates={data.map_data.coordinates}/>
                    </Box>
                </SimpleGrid>
            </Box>
            <Box
                // overflowX="auto"
                w="full"
            >
                <Box px={0} py={5}>
                    <Title>Пользователи</Title>
                </Box>
                <Table>
                    <Thead>
                        <Tr>
                            <Th>
                                <HStack spacing="3">
                                    {/*<Checkbox/>*/}
                                    <HStack spacing="1">
                                        <Text>Создан</Text>
                                        <Icon as={IoArrowDown} color="muted" boxSize="4"/>
                                    </HStack>
                                </HStack>
                            </Th>
                            <Th>Имя пользователя</Th>
                            <Th>Всего устройств</Th>
                            <Th>За сегодня</Th>
                            <Th>За вчера</Th>
                            <Th>За неделю</Th>
                            <Th>За месяц</Th>
                            <Th>За год</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {data.users.map((item: any) => (
                            <Tr key={item.id}>
                                <Td>
                                    {moment(item.created).format('DD.MM.YYYY, HH:mm')}
                                </Td>
                                <Td>
                                    {item.username}
                                </Td>
                                <Td>
                                    {item.stats.total_devices}
                                </Td>
                                <Td>
                                    {item.stats.modified_today}
                                </Td>
                                <Td>
                                    {item.stats.modified_yesterday}
                                </Td>
                                <Td>
                                    {item.stats.modified_week}
                                </Td>
                                <Td>
                                    {item.stats.modified_month}
                                </Td>
                                <Td>
                                    {item.stats.modified_year}
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </Box>
        </Box>
    )
}
