import {extendTheme} from '@chakra-ui/react';

const theme = extendTheme({
  config: {
    initialColorMode: 'light',
    useSystemColorMode: true,
  },
  colors: {
    customColor: {
      100: '#f1f1f1',
      200: '#e1e1e1',
      300: '#d1d1d1',
      400: '#c1c1c1',
      500: '#b1b1b1',
      600: '#a1a1a1',
      700: '#919191',
      800: '#818181',
      900: '#717171',
    },
  },
  fonts: {
    heading: 'Roboto, sans-serif',
    body: 'Roboto, sans-serif',
  },
  components: {
    Checkbox: {
      // The base style for the Checkbox
      baseStyle: {
        control: {
          _checked: {
            bg: "#39b382",
            borderColor: "#39b382",
            color: "white",
          },
        },
      },
    },
    Input: {
      baseStyle: {
        borderColor: "red.300",
        _focus: {
          borderColor: "blue.300",
        },
      },
    },
    Button: {
      baseStyle: {
        // borderRadius: 'none',
        // backgroundColor: 'purple.500',
        _hover: {
          // backgroundColor: 'purple.600',
        }
      },
      variants: {
        solid: (props) => ({
          backgroundColor: props.colorScheme === 'customColor' ? 'customColor.500' : null,
          _hover: {
            backgroundColor: props.colorScheme === 'customColor' ? 'customColor.600' : null,
          },
        }),
      },
    },
  },
});

export default theme;
