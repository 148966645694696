import {Box, Button, Container, Heading, Stack, Text} from '@chakra-ui/react'
import * as React from "react";
import {useLocation} from "wouter";
import {isStaff} from "../localStore";

export const Redirect = () => {
    const [location, setLocation] = useLocation();

    // console.log(localStorage);

    if (!isStaff() && localStorage.getItem("customer_id")) {
        setLocation(`/customers/${localStorage.getItem("customer_id")}`);
    } else {
        setLocation(`/customers`);
    }

    return (
        <Box as="section" bg="bg.surface">
        <Container py={{base: '16', md: '24'}}>
            <Stack spacing={{base: '8', md: '10'}}>
                <Stack spacing={{base: '4', md: '5'}} align="center">
                    <Text color="fg.muted" maxW="2xl" textAlign="center" fontSize="xl">
                        Перенаправление...
                    </Text>
                </Stack>
            </Stack>
        </Container>
    </Box>
    )
}
