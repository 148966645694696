import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent, AlertDialogFooter, AlertDialogHeader,
    AlertDialogOverlay,
    Badge,
    Box,
    Button,
    ButtonGroup, Checkbox,
    Container, FormControl, FormLabel, Heading,
    HStack,
    Icon, IconButton,
    Input,
    InputGroup,
    InputLeftElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay,
    Stack, Table, TableCaption, TableContainer, Tbody, Td,
    Text, Tfoot, Th, Thead, Tr,
    useBreakpointValue, useClipboard,
    useColorModeValue, useDisclosure,
} from '@chakra-ui/react'
import moment from "moment";
import * as React from 'react'
import {FiEdit2, FiRadio, FiSearch, FiTrash2} from 'react-icons/fi'
import {IoArrowDown} from "react-icons/io5";
import axios from "axios";
import {useEffect, useRef, useState} from "react";
import {useQuery} from "react-query";
import debounce from 'lodash/debounce';
import {ProjectEdit} from "../ProjectEdit/ProjectEdit";
import {Link, useLocation, useParams} from "wouter";
import {Link as ChakraLink, LinkProps} from '@chakra-ui/react'
import {axiosConfig} from "../../localStore";
import {API_ADDRESS} from "../../config";
import {Loading} from "../../pages/Loading";
import {UserEdit} from "../UserEdit/UserEdit";
import {FaLocationArrow} from "react-icons/fa";
import {BiCopy} from "react-icons/bi";
import {Title} from "../Title";
import {DataWithCopyFn} from "../DataWithCopyFn";

const fetchTableData = async (customerId: string, projectId: string, page: number, searchTerm: string) => {
    const {data} = await axios.get(`${API_ADDRESS}/manager/v1/customers/${customerId}/projects/${projectId}/devices?page=${page}&search=${searchTerm}`, axiosConfig());
    return data;
};

function getTextStatus(status: number): string {
    if (status === 2) return "Завершена";
    if (status === 3) return "Ошибка";
    return "В работе";
}

function getStatusColor(status: number): string {
    if (status === 2) return "green";
    if (status === 3) return "red";
    return "yellow";
}

function getQualityTypeColor(quality_type: number): string {
    if (quality_type === 1) return "green";
    if (quality_type === 2) return "yellow";
    return "red";
}

export const Devices = () => {
    const params = useParams();

    const isMobile = useBreakpointValue({base: true, md: false})
    const colorMode = useColorModeValue('sm', 'sm-dark')
    const borderRadius = useBreakpointValue({base: 'none', md: 'lg'})

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState<any | null>(null);

    const handleRowClick = (item: any) => {
        setSelectedItem(item);
        setIsModalOpen(true);
    }

    // delete
    const [isDeleteAlertOpen, setDeleteAlertOpen] = useState(false);
    const cancelRef = useRef<HTMLButtonElement | null>(null);
    const handleDeleteClick = (item: any) => {
        setSelectedItem(item);
        setDeleteAlertOpen(true);
    }
    const deleteSelectedClick = async (item: any) => {
        await axios.delete(`${API_ADDRESS}/manager/v1/customers/${params.customerId}/projects/${params.projectId}/devices/${item.id}`, axiosConfig());
        refetch();
        setDeleteAlertOpen(false);
    }

    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>(searchTerm);
    useEffect(() => {
        const handleDebounce = debounce(() => setDebouncedSearchTerm(searchTerm), 500);
        handleDebounce();

        return () => {
            handleDebounce.cancel();
        };
    }, [searchTerm]);

    const {data, isError, isLoading, refetch} =
        useQuery(['tabledevicesData', params.customerId, params.projectId, currentPage, debouncedSearchTerm], () =>
            fetchTableData(params.customerId, params.projectId, currentPage, debouncedSearchTerm), {
                     staleTime: 5000,
                     keepPreviousData: true // for better pagination transition
                 });

    if (isLoading) return <Loading/>;
    if (isError || !data) return <div>Error loading data</div>;

    // console.log(data);

    return (
        <Box
            // py={{ base: '4', md: '8' }}
            // px={{ base: '0', md: 8 }}
            p="0" m={0} w="100%"
        >
            <Box
                bg="bg-surface"
                boxShadow={{base: 'none', md: colorMode}}
                borderRadius={borderRadius}
            >
                <Stack spacing="5">
                    <Box px={{base: '4', md: '6'}} pt="5">
                        <Stack
                            direction={{base: 'column', md: 'row'}}
                            justify="space-between"
                        >
                            <Title>Устройства</Title>
                            <InputGroup maxW="xs">
                                <InputLeftElement pointerEvents="none">
                                    <Icon as={FiSearch} color="muted" boxSize="5"/>
                                </InputLeftElement>
                                <Input
                                    type="text"
                                    placeholder="Поиск"
                                    value={searchTerm}
                                    onChange={e => {
                                        setSearchTerm(e.target.value);
                                        setCurrentPage(1); // Reset to the first page when searching
                                    }}
                                />
                            </InputGroup>
                            {/*
                            <Button onClick={() => handleRowClick({})}>Создать</Button>
                            */}
                        </Stack>
                    </Box>
                    <Box
                        // overflowX="auto"
                        w="full"
                    >
                        <Table>
                            <Thead>
                                <Tr>
                                    <Th>
                                        <HStack spacing="3">
                                            {/*<Checkbox/>*/}
                                            <HStack spacing="1">
                                                <Text>Создана</Text>
                                                <Icon as={IoArrowDown} color="muted" boxSize="4"/>
                                            </HStack>
                                        </HStack>
                                    </Th>
                                    <Th>EUI</Th>
                                    <Th>Статус</Th>
                                    <Th>Качество сети</Th>
                                    <Th>Last Seen</Th>
                                    <Th>Серийный номер</Th>
                                    <Th>Адрес</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {data.items.map((item: any) => {
                                    return (
                                        <Tr key={item.id}>
                                            <Td>
                                                <HStack spacing="3">
                                                    {/*<Checkbox/>*/}
                                                    <Text whiteSpace="nowrap"
                                                          color="muted">{moment(item.created).format('DD.MM.YYYY, HH:mm:ss')}</Text>
                                                    {/*<Avatar name={member.name} src={member.avatarUrl} boxSize="10" />*/}
                                                </HStack>
                                            </Td>
                                            <Td>
                                                <DataWithCopyFn
                                                    link={`/customers/${params.customerId}/projects/${params.projectId}/devices/${item.id}`}
                                                    value={item.eui !== "" ? item.eui : "Не задан"}/>
                                            </Td>
                                            <Td>
                                                {/*
                                                <Badge bg={""} size="sm" colorScheme={getStatusColor(item.status)}>
                                                    {getTextStatus(item.status)}
                                                </Badge>
                                                */}
                                                {/*
                                                <Badge bg={""} size="sm" colorScheme={item.has_network_info ? "green" : "yellow"}>
                                                    {item.has_network_info ? "Качество сети" : "Нет качества сети"}
                                                </Badge>
                                                */}
                                                <Badge bg={""} size="sm" colorScheme={item.photos_count > 0 ? "green" : "yellow"}>
                                                    Фотографий: {item.photos_count}
                                                </Badge>
                                                {/*
                                                <Badge bg={""} size="sm" colorScheme={item.has_old_photos ? "green" : "yellow"}>
                                                    {item.has_old_photos ? "Старые фото" : "Нет старых фото"}
                                                </Badge>
                                                {' '}
                                                <Badge bg={""} size="sm" colorScheme={item.has_new_photos ? "green" : "yellow"}>
                                                    {item.has_new_photos ? "Новые фото" : "Нет новых фото"}
                                                </Badge>
                                                {' '}
                                                <Badge bg={""} size="sm" colorScheme={item.has_document_photos ? "green" : "yellow"}>
                                                    {item.has_document_photos ? "Акт" : "Нет акта"}
                                                </Badge>
                                                */}
                                                {/*
                                            <Badge size="sm" colorScheme={member.status === 'active' ? 'green' : 'red'}>
                                                {member.status}
                                            </Badge>
                                            */}
                                            </Td>
                                            <Td>
                                                <TableContainer>
                                                    <Table variant='unstyled' size={"xs"}>
                                                        {/*<Thead>*/}
                                                        {/*    <Tr>*/}
                                                        {/*        <Th>EUI</Th>*/}
                                                        {/*        <Th>RSSI</Th>*/}
                                                        {/*        <Th isNumeric>SNR</Th>*/}
                                                        {/*    </Tr>*/}
                                                        {/*</Thead>*/}
                                                        <Tbody>
                                                            {item.network_info.gateways.map((gw: any) => (
                                                                <Tr p={0} m={0} key={gw.eui}>
                                                                    <Td
                                                                        p={0}
                                                                        // color={getQualityTypeColor(gw.quality_type)}
                                                                        fontFamily="mono">
                                                                        <Badge bg={""} size="sm"
                                                                               colorScheme={getQualityTypeColor(gw.quality_type)}>
                                                                            <Box display="flex" alignItems="center">
                                                                                <Icon as={FiRadio} w={5} h={5} mr={2}/>
                                                                                <Text>{gw.eui}</Text>
                                                                            </Box>
                                                                        </Badge>
                                                                    </Td>
                                                                    <Td
                                                                        // color={getQualityTypeColor(gw.quality_type)}
                                                                        fontFamily="mono">
                                                                        <Badge bg={""} size="sm"
                                                                               colorScheme={getQualityTypeColor(gw.quality_type)}>
                                                                            RSSI {gw.rssi}
                                                                        </Badge>
                                                                    </Td>
                                                                    <Td
                                                                        // color={getQualityTypeColor(gw.quality_type)}
                                                                        fontFamily="mono" isNumeric>
                                                                        <Badge bg={""} size="sm"
                                                                               colorScheme={getQualityTypeColor(gw.quality_type)}>
                                                                            SNR {gw.snr}
                                                                        </Badge>
                                                                    </Td>
                                                                </Tr>
                                                            ))}
                                                        </Tbody>

                                                    </Table>
                                                </TableContainer>


                                                {/*
                                            <Text color="muted">{item.network_info.gateways.map((gw: any) => (
                                                <Box>
                                                    <Badge fontFamily="mono" size="sm" colorScheme={getQualityTypeColor(gw.quality_type)}>
                                                        EUI: {gw.eui}{' '}
                                                        RSSI: {gw.rssi}{' '}
                                                        SNR: {gw.snr}
                                                    </Badge>
                                                </Box>
                                            ))}</Text>
                                            */}
                                            </Td>
                                            <Td>
                                                {item.network_info.last_seen > 0 ?
                                                    <Text
                                                        color="muted">{moment.unix(item.network_info.last_seen / 1000).format('DD.MM.YYYY, HH:mm')}</Text>
                                                    :
                                                    <Text color="muted">-</Text>
                                                }
                                            </Td>
                                            <Td>
                                                <Text color="muted">
                                                    {item.serial_number}
                                                </Text>
                                            </Td>
                                            <Td>
                                                <Text color="muted">
                                                    {item.address}
                                                </Text>
                                            </Td>
                                            {/*
                                            <Td>
                                                <Text color="muted">
                                                    <Box display="flex" alignItems="center">
                                                        <Icon color={"gray.500"} as={FaLocationArrow} w={5} h={4} mr={2}/>
                                                        <Text>lat: {item.latitude}<br/>lng: {item.longitude}</Text>
                                                    </Box>
                                                </Text>
                                            </Td>
                                            */}
                                            {/*<Td>*/}
                                            {/*  <Text color="muted">{member.role}</Text>*/}
                                            {/*</Td>*/}
                                            {/*<Td>*/}
                                            {/*  <Text color="muted">*/}
                                            {/*    <Rating defaultValue={member.rating} size="xl" />*/}
                                            {/*  </Text>*/}
                                            {/*</Td>*/}
                                            <Td>
                                                <HStack spacing="1">
                                                    <IconButton
                                                        icon={<FiTrash2 fontSize="1.25rem"/>}
                                                        variant="ghost"
                                                        aria-label="Удалить"
                                                        onClick={() => handleDeleteClick(item)}
                                                    />
                                                </HStack>
                                            </Td>
                                        </Tr>
                                    )
                                })}
                            </Tbody>
                        </Table>
                    </Box>
                    <Box px={{base: '4', md: '6'}} pb="5">
                        <HStack spacing="3" justify="space-between">
                            {!isMobile && (
                                <Text color="muted" fontSize="sm">
                                    Страница {data.paginator.page} из {data.paginator.num_pages} ({data.paginator.count} записей)
                                </Text>
                            )}
                            <ButtonGroup
                                spacing="3"
                                justifyContent="space-between"
                                width={{base: 'full', md: 'auto'}}
                                variant="secondary"
                            >
                                <Button
                                    onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                                    disabled={data.paginator.page === 1}
                                >Предыдущая</Button>
                                <Button
                                    onClick={() => setCurrentPage(prev => prev + 1)}
                                    disabled={data.paginator.page === data.paginator.num_pages}
                                >Следующая</Button>
                            </ButtonGroup>
                        </HStack>
                    </Box>
                </Stack>
            </Box>
            {/*
            {isModalOpen && <UserEdit customer={customer} customerId={params.customerId} item={selectedItem} onClose={() => {
                setIsModalOpen(false);
                refetch()
            }}/>}
            */}
            <AlertDialog
                isOpen={isDeleteAlertOpen}
                leastDestructiveRef={cancelRef}
                onClose={() => setDeleteAlertOpen(false)}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Удаление устройства
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Вы точно хотите удалить устройство {selectedItem && selectedItem.eui !== "" ? selectedItem.eui : "без EUI"}?
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={() => setDeleteAlertOpen(false)}>
                                Отменить
                            </Button>
                            <Button colorScheme='red' onClick={() => deleteSelectedClick(selectedItem)} ml={3}>
                                Удалить
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </Box>
    )
}
