import {
    Badge,
    Box, FlexProps,
    Heading,
    HStack,
    Icon,
    Menu, MenuButton, MenuDivider, MenuItem, MenuItemOption, MenuList, MenuOptionGroup,
    Stack,
    Text,
    Tooltip,
    useBreakpointValue,
    useColorModeValue, useMenuButton,
} from '@chakra-ui/react'
import * as React from 'react'
import {FiArrowDownRight, FiArrowUpRight, FiMoreVertical} from 'react-icons/fi'

interface Props {
    label: string
    description?: any
    value: any
    delta?: {
        value: string
        isUpwardsTrend: boolean
    }
}

export const Stat = (props: Props) => {
    const {label, description, value, delta, ...boxProps} = props

    // const buttonProps = useMenuButton()

    const size = useBreakpointValue({base: 'sm', md: 'md'})

    return (
        <Box
            px={{base: '4', md: '6'}}
            py={{base: '5', md: '6'}}
            bg="bg-surface"
            borderRadius="lg"
            boxShadow={useColorModeValue('sm', 'sm-dark')}
            {...boxProps}
        >
            <Stack>
                <HStack justify="space-between">
                    <Text fontSize="sm" color="muted">
                        {label}
                    </Text>
                </HStack>
                <HStack justify="space-between">
                    {description !== undefined ? (
                        <Tooltip
                            hasArrow
                            shouldWrapChildren
                            placement="top" label={description} bg='gray.300' color='black'>
                            <Heading size={size}>{value}</Heading>
                        </Tooltip>
                    ) : (
                        <Heading size={size}>{value}</Heading>
                    )}
                    {delta && delta.value !== "0" && (
                        <Badge variant="subtle"
                               colorScheme={delta.isUpwardsTrend === undefined ? "blue" : delta.isUpwardsTrend ? 'green' : 'red'}>
                            <HStack spacing="1">
                                {delta.isUpwardsTrend !== undefined &&
                                  <Icon as={delta.isUpwardsTrend ? FiArrowUpRight : FiArrowDownRight}/>
                                }
                                <Text>{delta.value}</Text>
                            </HStack>
                        </Badge>
                    )}
                </HStack>
            </Stack>

        </Box>
    )
}

