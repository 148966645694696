import {
    Badge,
    Box,
    Button,
    ButtonGroup, Checkbox,
    Container, FormControl, FormLabel, Heading,
    HStack,
    Icon, IconButton,
    Input,
    InputGroup,
    Image,
    InputLeftElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid,
    Stack, Table, TableCaption, TableContainer, Tbody, Td,
    Text, Tfoot, Th, Thead, Tr,
    useBreakpointValue, useClipboard,
    useColorModeValue, useDisclosure,
} from '@chakra-ui/react'
import moment from "moment";
import * as React from 'react'
import {FiEdit2, FiRadio, FiSearch, FiTrash2} from 'react-icons/fi'
import {IoArrowDown} from "react-icons/io5";
import axios from "axios";
import {useEffect, useState} from "react";
import {useQuery} from "react-query";
import debounce from 'lodash/debounce';
import {ProjectEdit} from "../ProjectEdit/ProjectEdit";
import {Link, useLocation, useParams} from "wouter";
import {Link as ChakraLink, LinkProps} from '@chakra-ui/react'
import {axiosConfig} from "../../localStore";
import {API_ADDRESS} from "../../config";
import {Loading} from "../../pages/Loading";
import {UserEdit} from "../UserEdit/UserEdit";
import {FaLocationArrow} from "react-icons/fa";
import {BiCopy} from "react-icons/bi";
import {Stat} from "../Stat";
import {Title} from "../Title";
import {HeatMap} from "../Map/Map";
import {DataWithCopyFn} from "../DataWithCopyFn";

const fetchTableData = async (customerId: string, projectId: string, taskId: string, page: number, searchTerm: string) => {
    const url = `${API_ADDRESS}/manager/v1/customers/${customerId}/projects/${projectId}/tasks/${taskId}/actions?page=${page}&search=${searchTerm}`
    const {data} = await axios.get(url, axiosConfig());
    return data;
};

function getStatusColor(status: number): string {
    if (status === 1) return "green";
    if (status === 2) return "red";
    return "yellow";
}

function getPhotoUrl(photo: any) {
    if (photo.uploaded_to_cloud) {
        return `${photo.cdn_url}`;
    }
    return `${API_ADDRESS}/content/v1/task-photos/${photo.id}?token=${photo.token}`;
}

export const Task = (props: { task: any }) => {
    const params = useParams();

    const isMobile = useBreakpointValue({base: true, md: false})
    const colorMode = useColorModeValue('sm', 'sm-dark')
    const borderRadius = useBreakpointValue({base: 'none', md: 'lg'})

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState<any | null>(null);

    const handleRowClick = (item: any) => {
        setSelectedItem(item);
        setIsModalOpen(true);
    }

    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>(searchTerm);
    useEffect(() => {
        const handleDebounce = debounce(() => setDebouncedSearchTerm(searchTerm), 500);
        handleDebounce();

        return () => {
            handleDebounce.cancel();
        };
    }, [searchTerm]);

    const {data, isError, isLoading, refetch} =
        useQuery(['tableTaskActionsData', params.customerId, params.projectId, params.taskId, currentPage, debouncedSearchTerm], () =>
            fetchTableData(params.customerId, params.projectId, params.taskId, currentPage, debouncedSearchTerm), {
                     staleTime: 5000,
                     keepPreviousData: true // for better pagination transition
                 });

    if (isLoading || !props.task) return <Loading/>;
    if (isError || !data) return <div>Error loading data</div>;

    // console.log(data);

    return (
        <Box
            // py={{ base: '4', md: '8' }}
            // px={{ base: '0', md: 8 }}
            p="0" m={0} w="100%"
        >
            <Box
                bg="bg-surface"
                boxShadow={{base: 'none', md: colorMode}}
                borderRadius={borderRadius}
            >
                <Box px={5} pt={5}><Title>Задача</Title></Box>
                <Stack spacing="1">
                    <Stack spacing={{base: '5', lg: '6'}}>
                        <SimpleGrid columns={{base: 1, md: 3}} gap="6">
                            <Stat label={"EUI"} value={<DataWithCopyFn
                                link={`/customers/${params.customerId}/projects/${params.projectId}/devices/${props.task.device_id}`}
                                value={props.task.eui !== "" ? props.task.eui : "Не задан"}/>}/>
                            <Stat label={"Дата создания"} value={moment(props.task.created).format('DD.MM.YYYY, HH:mm')}/>
                            <Stat label={"Статус"} value={props.task.status_human}/>
                        </SimpleGrid>
                    </Stack>

                    <Box
                        // overflowX="auto"
                        w="full"
                    >
                        <SimpleGrid columns={2} gap="6">
                            <Box
                                // overflowX="auto"
                                w="full"
                            >
                                <Box px={5} py={5}><Title>Фотографии {!props.task.photos ? 'отсутствуют' : ''}</Title></Box>

                                {props.task.photos &&
                                  <Stack px={5} py={5} spacing={{base: '5', lg: '6'}}>
                                    <SimpleGrid columns={3} gap="6">
                                        {props.task.photos && props.task.photos.map((photo: any) => {
                                            return (
                                                <Box>
                                                    <a
                                                        href={getPhotoUrl(photo)}
                                                        target="_blank">
                                                        <Image
                                                            src={getPhotoUrl(photo)}
                                                            alt={photo.file_name}/>
                                                    </a>
                                                    <Box py={3} fontSize={"14"}>
                                                        {photo.type_human}
                                                    </Box>
                                                </Box>
                                            )
                                        })}
                                    </SimpleGrid>
                                  </Stack>
                                }
                            </Box>
                            <Box
                                // overflowX="auto"
                                w="full"
                                minH={"250px"}
                            >
                                <Box px={0} py={5}>
                                    <Title>Карта</Title>
                                </Box>
                                <HeatMap latitude={props.task.latitude} longitude={props.task.longitude}/>
                            </Box>
                        </SimpleGrid>
                    </Box>

                    <Box
                        // overflowX="auto"
                        w="full"
                    >
                        <Box px={5} py={5}><Title>История операций</Title></Box>
                        <Table>
                            <Thead>
                                <Tr>
                                    <Th>
                                        <HStack spacing="3">
                                            {/*<Checkbox/>*/}
                                            <HStack spacing="1">
                                                <Text>Дата</Text>
                                                <Icon as={IoArrowDown} color="muted" boxSize="4"/>
                                            </HStack>
                                        </HStack>
                                    </Th>
                                    <Th>Тип</Th>
                                    <Th>Статус</Th>
                                    <Th>Данные</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {data.items && data.items.map((item: any) => {
                                    return (
                                        <Tr key={item.id}>
                                            <Td>
                                                <HStack spacing="3">
                                                    {/*<Checkbox/>*/}
                                                    <Text whiteSpace="nowrap"
                                                          color="muted">{moment(item.created).format('DD.MM.YYYY, HH:mm:ss')}</Text>
                                                    {/*<Avatar name={member.name} src={member.avatarUrl} boxSize="10" />*/}
                                                </HStack>
                                            </Td>
                                            <Td>
                                                <Badge bg={""} size="sm" colorScheme={getStatusColor(item.status)}>
                                                    {item.action_human}
                                                </Badge>

                                            </Td>
                                            <Td>
                                                <Badge bg={""} size="sm" colorScheme={getStatusColor(item.status)}>
                                                    {item.status_human}
                                                </Badge>
                                            </Td>
                                            <Td>
                                                <Box display="flex"
                                                     fontFamily="mono"
                                                     alignItems="center">
                                                    {item.payload_text}
                                                </Box>
                                            </Td>
                                        </Tr>
                                    )
                                })}
                            </Tbody>
                        </Table>
                    </Box>
                    <Box px={{base: '4', md: '6'}} pb="5">
                        <HStack spacing="3" justify="space-between">
                            {!isMobile && (
                                <Text color="muted" fontSize="sm">
                                    Страница {data.paginator.page} из {data.paginator.num_pages} ({data.paginator.count} записей)
                                </Text>
                            )}
                            <ButtonGroup
                                spacing="3"
                                justifyContent="space-between"
                                width={{base: 'full', md: 'auto'}}
                                variant="secondary"
                            >
                                <Button
                                    onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                                    disabled={data.paginator.page === 1}
                                >Предыдущая</Button>
                                <Button
                                    onClick={() => setCurrentPage(prev => prev + 1)}
                                    disabled={data.paginator.page === data.paginator.num_pages}
                                >Следующая</Button>
                            </ButtonGroup>
                        </HStack>
                    </Box>
                </Stack>
            </Box>
            {/*
            {isModalOpen && <UserEdit customer={customer} customerId={params.customerId} item={selectedItem} onClose={() => {
                setIsModalOpen(false);
                refetch()
            }}/>}
            */}
        </Box>
    )
}
