import {
    Badge,
    Box,
    Button,
    ButtonGroup, Checkbox,
    Container, FormControl, FormLabel, Heading,
    HStack,
    Icon, IconButton,
    Input,
    InputGroup,
    InputLeftElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Spinner,
    Stack, Table, Tbody, Td,
    Text, Textarea, Th, Thead, Tr,
    useBreakpointValue,
    useColorModeValue, useDisclosure, useToast,
} from '@chakra-ui/react'
import * as React from 'react'
import axios, {AxiosError} from "axios";
import {useEffect, useState} from "react";
import {useMutation, UseMutationResult} from 'react-query';
import {API_ADDRESS} from "../../config";
import {axiosConfig} from "../../localStore";
import moment from "moment";
import {useParams} from "wouter";


interface Project {
    id: string;
    title: string;
}

interface Customer {
    projects: Project[];
}

interface ModalProps {
    customer: Customer;
    customerId: string;
    item: any;
    onClose: () => void;
}

const saveEditedItem = async ({customerId, item}: { customerId: number, item: any }) => {
    let url = `${API_ADDRESS}/manager/v1/customers/${customerId}/users`
    if (item.id) {
        url = `${API_ADDRESS}/manager/v1/customers/${customerId}/users/${item.id}`
    }
    const {data} = await axios.post(url, item, axiosConfig());
    return data;
};

export const UserEdit: React.FC<ModalProps> = ({customer, customerId, item, onClose}) => {
    const params = useParams();

    const {isOpen, onOpen} = useDisclosure()
    const [editedUsername, setEditedUsername] = useState("");
    const [editedPassword, setEditedPassword] = useState("");
    const [editedFirstName, setEditedFirstName] = useState(item.first_name);
    const [editedLastName, setEditedLastName] = useState(item.last_name);
    const [editedPhone, setEditedPhone] = useState(item.phone);
    const [editedEmail, setEditedEmail] = useState(item.email);
    const [editedComment, setEditedComment] = useState(item.comment);
    const [selectedProject, setSelectedProject] = useState(item.active_project_id); // Added state for selected project
    const [isAdmin, setIsAdmin] = useState(item.is_admin);


    // useEffect(() => {
    //     setEditedFirstName(item.first_name);
    // }, [item]);
    // useEffect(() => {
    //     setEditedLastName(item.last_name);
    // }, [item]);

    const toast = useToast();
    const mutation: UseMutationResult<any, Error, any> = useMutation(saveEditedItem, {
        onSuccess: () => {
            // queryClient.invalidateQueries('tableData');
            onClose();
        },
        onError: (error: AxiosError) => {
            console.error("Error saving item:", error);
            // console.log(error?.response?.data?.message);
            // @ts-ignore
            let message = error?.response?.data?.message || "Неизвестная ошибка";
            toast({
                      title: "Ошибка",
                      description: message,
                      status: "error",
                      duration: 3000,
                      isClosable: true,
                  });
            // Handle the error, maybe show a notification or some feedback
        }
    });

    const handleSave = () => {
        mutation.mutate(
            {
                customerId,
                item: {
                    ...item,
                    username: editedUsername,
                    password: editedPassword,
                    first_name: editedFirstName,
                    last_name: editedLastName,
                    phone: editedPhone,
                    email: editedEmail,
                    comment: editedComment,
                    active_project_id: selectedProject,
                    is_admin: isAdmin,
                    project_id: params.projectId,
                }
            });
    }

    if (!item) return null;

    return (
        <Modal
            isOpen={true}
            onClose={onClose}
        >
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader>{!item.id ? "Создание" : "Редактирование"} пользователя</ModalHeader>
                <ModalCloseButton/>
                <ModalBody pb={6}>
                    {item.id &&
                      <Box pb={5}>
                        <Heading size='xs' textTransform='uppercase'>
                            {item.username}
                        </Heading>
                        <Text pt='2' fontSize='sm'>
                            {moment(item.created).format('DD.MM.YYYY, HH:mm:ss')}
                        </Text>
                      </Box>
                    }

                    {!item.id &&
                      <FormControl>
                        <FormLabel>Имя пользователя</FormLabel>
                        <Input
                          value={editedUsername}
                          onChange={(e) => setEditedUsername(e.target.value)}
                        />
                      </FormControl>
                    }

                    <FormControl mt={4}>
                        <FormLabel>Новый пароль</FormLabel>
                        <Input
                            value={editedPassword}
                            onChange={(e) => setEditedPassword(e.target.value)}
                            type={"password"}/>
                    </FormControl>

                    <FormControl mt={4}>
                        <FormLabel>Имя</FormLabel>
                        <Input
                            value={editedFirstName}
                            onChange={(e) => setEditedFirstName(e.target.value)}
                            placeholder='Имя'/>
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>Фамилия</FormLabel>
                        <Input
                            value={editedLastName}
                            onChange={(e) => setEditedLastName(e.target.value)}
                            placeholder='Фамилия'/>
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>Телефон</FormLabel>
                        <Input
                            value={editedPhone}
                            onChange={(e) => setEditedPhone(e.target.value)}
                            placeholder='Телефон'/>
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>Эл. адрес</FormLabel>
                        <Input
                            value={editedEmail}
                            onChange={(e) => setEditedEmail(e.target.value)}
                            placeholder='user@domain'/>
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>Комментарий</FormLabel>
                        <Textarea
                            value={editedComment}
                            onChange={(e) => setEditedComment(e.target.value)}
                            placeholder='Комментарий'/>
                    </FormControl>
                      <FormControl mt={4}>
                        <FormLabel>Активный проект</FormLabel>
                        <Select
                          value={selectedProject}
                          onChange={(e) => setSelectedProject(e.target.value)}>
                          <option value="">Проект не выбран</option>
                            {customer.projects && customer.projects.map(project => (
                                <option key={project.id} value={project.id}>
                                    {project.title}
                                </option>
                            ))}
                        </Select>
                      </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>Администратор</FormLabel>
                        <Checkbox
                            isChecked={isAdmin}
                            onChange={(e) => setIsAdmin(e.target.checked)}>
                            Да
                        </Checkbox>
                    </FormControl>
                </ModalBody>

                <ModalFooter>
                    {mutation.isLoading ?
                        <Button
                            colorScheme='blue'
                            mr={3}
                            disabled={true}
                            rightIcon={<Spinner/>}
                        >
                            Сохранение...
                        </Button>
                        :
                        <Button
                            onClick={handleSave}
                            colorScheme='blue'
                            mr={3}
                        >
                            Сохранить
                        </Button>
                    }
                    <Button onClick={onClose}>Отменить</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
