import * as React from 'react';
import ReactDOM from 'react-dom';
import {createRoot} from 'react-dom/client';
import {ChakraProvider, CSSReset, Box} from '@chakra-ui/react';
// import { extendTheme } from '@chakra-ui/react';

import App from './App';
import theme from "./theme";
import {QueryClient, QueryClientProvider} from "react-query";
import {ReactQueryDevtools} from "react-query/devtools";

const container = document.getElementById('root');
const root = createRoot(container!)
const queryClient = new QueryClient()

root.render(
    // <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <ChakraProvider theme={theme}>
                <CSSReset/>
                <App/>
            </ChakraProvider>
            <ReactQueryDevtools initialIsOpen/>
        </QueryClientProvider>
    // </React.StrictMode>
);

