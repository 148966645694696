import {Box, Button, Container, Heading, Spinner, Stack, Text} from '@chakra-ui/react'
import * as React from "react";

export const Loading = () => (
    <Box as="section" bg="bg.surface">
        <Container py={{base: '16', md: '24'}}>
            <Stack spacing={{base: '8', md: '10'}}>
                <Stack spacing={{base: '4', md: '5'}} align="center">
                    <Box position="relative" display="inline-block">
                        <Spinner />
                    </Box>

                    <Text color="fg.muted" maxW="2xl" textAlign="center" fontSize="xl">
                        Загружаем...
                    </Text>
                </Stack>
            </Stack>
        </Container>
    </Box>
)
