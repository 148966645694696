import {Box, Button, Container, Heading, Stack, Text} from '@chakra-ui/react'
import * as React from "react";
import {useLocation} from "wouter";

/* eslint-disable no-restricted-globals */
export const Logout = () => {
    const [location, setLocation] = useLocation();

    localStorage.setItem("token", "");

    setLocation("/");

    window.location.reload();

    return (
        <Box as="section" bg="bg.surface">
        <Container py={{base: '16', md: '24'}}>
            <Stack spacing={{base: '8', md: '10'}}>
                <Stack spacing={{base: '4', md: '5'}} align="center">
                    <Box position="relative" display="inline-block">
                        <Text fontSize={90}>👋</Text>
                    </Box>

                    <Text color="fg.muted" maxW="2xl" textAlign="center" fontSize="xl">
                        Очистка сессии...
                    </Text>
                </Stack>
            </Stack>
        </Container>
    </Box>
    )
}
