import {Box, Icon, useClipboard} from "@chakra-ui/react";
import {Link} from "wouter";
import {Link as ChakraLink} from "@chakra-ui/react"
import {BiCopy} from "react-icons/bi";
import * as React from "react";


export function DataWithCopyFn(props: { link?: any, value: any }) {
    const {hasCopied, onCopy} = useClipboard(props.value);
    return (
        <Box display="flex"
             fontFamily="mono"
             alignItems="center">
            {props.link ?
                <Link to={props.link}>
                    <ChakraLink color='teal.500'>
                        {props.value}
                    </ChakraLink>
                </Link>
                :
                <>
                    {props.value}
                </>
            }
            <Icon
                cursor={"pointer"}
                onClick={onCopy}
                _hover={{color: "gray.500"}}
                as={BiCopy} w={5} h={4} ml={2}/>
        </Box>
    );
}
