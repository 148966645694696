import {Box, Button, Container, Heading, Stack, Text} from '@chakra-ui/react'
import * as React from "react";

export const Soon = () => (
    <Box as="section" bg="bg.surface">
        <Container py={{base: '16', md: '24'}}>
            <Stack spacing={{base: '8', md: '10'}}>
                <Stack spacing={{base: '4', md: '5'}} align="center">
                    <Box position="relative" display="inline-block">
                        <Text fontSize={90}>👨‍💻</Text>
                        <Text
                            position="absolute"
                            top="-5px"
                            right="-5px"
                            fontSize="lg"
                            borderRadius="50%"
                            bgColor="white"
                            width="1.2em"
                            height="1.2em"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Box fontSize={38}>💭</Box>
                        </Text>
                    </Box>

                    <Text color="fg.muted" maxW="2xl" textAlign="center" fontSize="xl">
                        В процессе разработки...
                    </Text>
                </Stack>
            </Stack>
        </Container>
    </Box>
)
